import { Footer as FeatureFooter } from '@/features/Footer';

const Footer = (props) => {
    const { disabled } = props;

    if (disabled) {
        return null;
    }

    return <FeatureFooter {...props} />;
};

export default Footer;
